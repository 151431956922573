import React from 'react';
import { darkTheme, lightTheme } from '../../customTheme.js';
import { ResponsiveAppBar } from '../../components/ResponsiveAppBar.js';
import FeaturePanel from '../../components/FeaturePanel.js';
import Footer from '../../components/Footer.js';
import NewsletterPanel from '../../components/newsletter-panel/NewsletterPanel.js';
import { Helmet } from 'react-helmet-async';
import GridFeaturePanel from '../../components/GridFeaturePanel.js';
import ToolsCard from '../../components/ToolsCard.tsx';
import { Typography } from '@mui/material';

import SalesDashboard from './components/SalesDashboard.tsx';
import Panel from '../../components/Panel.js';
import { colors } from '../../customTheme.js'
import Intercom from '@intercom/messenger-js-sdk';
import Testimonials from './components/Testimonials.tsx';

const Home = () => {
  Intercom({
    app_id: 'khe0tarm',
  });

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <Helmet>
        <title>Poshwatch - Discover The Top Selling Products on Poshmark</title>
        <meta name="description" content={"Uncover top selling brands & categories to make more sales on Poshmark. Use our reseller tools to grow your Poshmark business."} />
        <link rel="canonical" href={"https://poshwatch.io/"} />
      </Helmet>

      <div style={{ position: 'relative', zIndex: 4 }}>
        <ResponsiveAppBar style={{ position: "absolute", top: 0, left: 0, zIndex: 1000, backgroundColor: "transparent" }} />

        <div style={{
          position: 'relative',
        }}>
          <FeaturePanel
            title={
              <Typography
                fontFamily="Libre Baskerville"
                variant="h1"
                align="center"
                paddingBottom={3}
                sx={{
                  fontSize: {
                    xs: '2.5em',
                    sm: '3.3em',
                  },
                  color: 'rgba(255, 255, 255, 0.9)',
                  textShadow: `2px 2px 4px rgba(0, 0, 0, 0.2`,
                }}
              >
                Items <span style={{ color: colors.wafer }}>not </span> selling on Poshmark?
              </Typography>
            }
            subtitle={'Poshwatch helps you improve your listings and uncover the top-selling products on Poshmark.'}
            theme={darkTheme}
            backgroundColor={colors.revolverPurple}
            style={{
              position: 'relative',
              zIndex: 5,
            }}
          >
            <ToolsCard />
          </FeaturePanel>
        </div>

        <Panel theme={lightTheme}>
          <SalesDashboard />
        </Panel>

        <div style={{ position: 'relative', zIndex: 4 }}>
          <GridFeaturePanel
            theme={lightTheme}
            backgroundColor={colors.seaShell}
          />
        </div>

        <Panel theme={lightTheme}>
          <Testimonials testimonials />
        </Panel>

        <NewsletterPanel compact={false} />
        <Footer />
      </div>
    </div >
  );
};

export default Home;