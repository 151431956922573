import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Panel from './Panel';
import HeroButton from './HeroButton.tsx';
import { useNavigate } from 'react-router-dom';
import PATHS from '../utils/paths';
import { useAuth } from '../contexts/AuthContext';

const FeaturePanel = ({
  title,
  subtitle,
  style,
  theme,
  backgroundColor,
  children,
}) => {
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();

  return (
    <Panel
      theme={theme}
      backgroundColor={backgroundColor}
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '90vh',
      }}
    >
      <Box
        sx={{
          paddingTop: 10,
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Container maxWidth='sm'>
          {title}
          <Typography
            variant="h2"
            align="center"
            color="textSecondary"
            style={{
              fontSize: "1.3em",
              fontWeight: 400,
              lineHeight: 1.6,
            }}
            sx={{
              pl: {
                xs: 4,
                sm: 0,
              },
              pr: {
                xs: 4,
                sm: 0,
              },
              pb: 5
            }}
          >
            {subtitle}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', pb: 10 }}>
            <HeroButton
              onClick={() => navigate(
                isSignedIn ? PATHS.TOP_SELLERS : PATHS.REGISTER)}
            >
              {'Start here for free'}
            </HeroButton>
          </Box>
        </Container>
        <Container maxWidth='sm'>
          {children}
        </Container>
      </Box>
    </Panel>
  );
};

export default FeaturePanel;